[data-bs-theme='light'] {
  header {
    .dropdown-item.active {
      color: $dark;
    }
  }

  footer {
    .dropdown-item.active {
      color: $dark;
    }
  }
}

img.fur-profile {
  width: 300px;
  height: 300px;
}

.profile-pic {
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}

.fursuiter-card {
  a {
    transition: all 0.35s ease-in-out;
    &:hover {
      .card {
        border-color: var(--bs-tertiary-bg);
        .card-body {
          background: var(--bs-secondary-bg);
        }
      }
    }
  }

  .card {
    transition: all 0.35s ease-in-out;
    .card-body {
      transition: all 0.35s ease-in-out;
      background: var(--bs-tertiary-bg);
    }
  }

  .name {
    font-size: 22px;
    font-weight: bold;
  }

  .idd {
    font-size: 14px;
    font-weight: 600;
  }

  .idd1 {
    font-size: 12px;
  }

  .text span {
    font-size: 13px;

    font-weight: 500;
  }
}

.bd-navbar {
  padding: 0.75rem 0;
  background-color: transparent;
  box-shadow:
    0 0.5rem 1rem rgba(0, 0, 0, 0.15),
    inset 0 -1px 0 rgba(255, 255, 255, 0.15);
}
.bd-navbar::after {
  position: absolute;
  inset: 0;
  z-index: -1;
  display: block;
  content: '';
  background-image: linear-gradient(
    rgba(var(--bd-violet-rgb), 1),
    rgba(var(--bd-violet-rgb), 0.95)
  );
}
@media (max-width: 991.98px) {
  .bd-navbar .bd-navbar-toggle {
    width: 4.25rem;
  }
}
.bd-navbar .navbar-toggler {
  padding: 0;
  margin-right: -0.5rem;
  border: 0;
}
.bd-navbar .navbar-toggler:first-child {
  margin-left: -0.5rem;
}
.bd-navbar .navbar-toggler .bi {
  width: 1.5rem;
  height: 1.5rem;
}
.bd-navbar .navbar-toggler:focus {
  box-shadow: none;
}
.bd-navbar .navbar-brand {
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bd-navbar .navbar-brand {
    transition: none;
  }
}
.bd-navbar .navbar-brand:hover {
  transform: rotate(-5deg) scale(1.1);
}

.bd-navbar .nav-link {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.bd-navbar .navbar-toggler.active,
.bd-navbar .nav-link.active {
  font-weight: 600;
}
.bd-navbar .navbar-nav-svg {
  display: inline-block;
  vertical-align: -0.125rem;
}
.bd-navbar .offcanvas-lg {
  background-color: var(--bd-violet-bg);
  border-left: 0;
}
@media (max-width: 991.98px) {
  .bd-navbar .offcanvas-lg {
    box-shadow: var(--bs-box-shadow-lg);
  }
}
.bd-navbar .dropdown-toggle:focus:not(:focus-visible) {
  outline: 0;
}
.bd-navbar .dropdown-menu {
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-padding-x: 0.25rem;
  --bs-dropdown-padding-y: 0.25rem;
  --bs-dropdown-link-hover-bg: rgba(var(--bd-violet-rgb), 0.1);
  --bs-dropdown-link-active-bg: rgba(var(--bd-violet-rgb), 1);
  --bs-dropdown-font-size: 0.875rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  box-shadow: var(--bs-box-shadow);
}
.bd-navbar .dropdown-menu li + li {
  margin-top: 0.125rem;
}
.bd-navbar .dropdown-menu .dropdown-item {
  border-radius: 0.25rem;
}
.bd-navbar .dropdown-menu .dropdown-item:active .bi {
  color: inherit !important;
}
.bd-navbar .dropdown-menu .active {
  font-weight: 600;
}
.bd-navbar .dropdown-menu .active .bi {
  display: block !important;
}
.bd-navbar .dropdown-menu-end {
  --bs-dropdown-min-width: 8rem;
}
[data-bs-theme='dark'] .bd-navbar {
  box-shadow:
    0 0.5rem 1rem rgba(0, 0, 0, 0.15),
    inset 0 -1px 0 rgba(255, 255, 255, 0.15);
}

.bd-navbar .bi {
  width: 1em;
  height: 1em;
}

.bd-navbar .nav-link {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.bd-navbar .nav-link.active {
  font-weight: 600;
}
.bd-navbar svg {
  fill: currentColor;
}

.skippy {
  background-color: #4c0bce;
}
.skippy a {
  color: #fff;
}

[data-bs-theme='dark'] {
  .subheader {
    background: #444048;
  }
}
nav-link {
  color: var(--bs-white);
  &:visited {
    color: var(--bs-white);
  }
}

[data-bs-theme='light'] {
  .nav-link {
    color: var(--bs-white);
    &:visited {
      color: var(--bs-white);
    }
  }
  .subheader {
    background: #ffbc89;
  }
}

.navbar {
  .topbar-divider {
    width: 0;
    border-right: 1px solid $white;
    height: calc(4.375rem - 2rem);
    margin: auto 0.5rem;
  }
  .img-profile {
    height: 2rem;
    width: 2rem;
  }
}

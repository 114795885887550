#content {
  padding-bottom: 5rem;
  min-height: 88vh;
}

body {
  // overflow-x: hidden;
  position: relative;
}

html:not([data-bs-theme='light']) {
  body {
    @media (prefers-color-scheme: dark) {
      background-color: #212529;
    }
  }
}

html {
  opacity: 0;
  &.loaded {
    opacity: 1;
    // transition: opacity 0.5s;
  }
}

.underline {
  position: relative;
}

.underline:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: var(--bs-canfur-primary);
  z-index: 0;
}

.rounded.img-fluid.shadow.w-100.fit-cover {
  max-height: 350px;
  object-fit: cover;
}

.card-flex {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

[data-bs-theme='dark'] {
  header {
    .dropdown-item.active {
      color: $white;
    }
  }

  footer {
    .dropdown-item.active {
      color: $white;
    }
  }

  .btn-canfur-primary {
    @extend .btn-canfur-primary-dark;
  }

  .btn-canfur-primary-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #4a148c;
    --bs-btn-border-color: #4a148c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3f1177;
    --bs-btn-hover-border-color: #3b1070;
    --bs-btn-focus-shadow-rgb: 101, 55, 157;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3b1070;
    --bs-btn-active-border-color: #380f69;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #4a148c;
    --bs-btn-disabled-border-color: #4a148c;
  }
}

.login-page {
  .bg-login-image {
    background-size: cover;
    background-position: center center;
    min-height: 500px;
  }

  #content {
    padding-bottom: 0;
  }

  /* bg image
  https://commons.wikimedia.org/wiki/File:Paw-print.svg
  https://commons.wikimedia.org/wiki/File:Fox-tail_-_Delapouite_-_game-icons.svg
  This file is licensed under the Creative Commons Attribution-Share Alike 3.0 Unported license.
  */
  background: var(--bs-body-bg) url(/assets/img/pattern.svg);
  background-blend-mode: color-dodge;

  iframe {
    margin: auto !important;
  }

  .login-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: -1;
    background-size: cover;
    background-position: center;
    filter: blur(10px);
    transition: opacity 1s ease-in-out;
  }
}

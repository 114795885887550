[data-bs-theme=dark] {
  --bs-canfur-primary-rgb: 74, 20, 140;
  --bs-canfur-primary: #4a148c;
}

#content {
  padding-bottom: 5rem;
  min-height: 88vh;
}

body {
  position: relative;
}

@media (prefers-color-scheme: dark) {
  html:not([data-bs-theme=light]) body {
    background-color: #212529;
  }
}

html {
  opacity: 0;
}
html.loaded {
  opacity: 1;
}

.underline {
  position: relative;
}

.underline:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: var(--bs-canfur-primary);
  z-index: 0;
}

.rounded.img-fluid.shadow.w-100.fit-cover {
  max-height: 350px;
  object-fit: cover;
}

.card-flex {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

footer p {
  color: var(--bs-white);
}
footer a {
  color: var(--bs-white);
}
footer a:visited {
  color: var(--bs-white);
}

footer a {
  text-decoration: none;
}
footer a:hover, footer a:focus {
  text-decoration: underline;
}

.bd-navbar {
  padding: 0.75rem 0;
  background-color: transparent;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(255, 255, 255, 0.15);
}

.bd-navbar::after {
  position: absolute;
  inset: 0;
  z-index: -1;
  display: block;
  content: "";
  background-image: linear-gradient(rgba(var(--bd-violet-rgb), 1), rgba(var(--bd-violet-rgb), 0.95));
}

@media (max-width: 991.98px) {
  .bd-navbar .bd-navbar-toggle {
    width: 4.25rem;
  }
}
.bd-navbar .navbar-toggler {
  padding: 0;
  margin-right: -0.5rem;
  border: 0;
}

.bd-navbar .navbar-toggler:first-child {
  margin-left: -0.5rem;
}

.bd-navbar .navbar-toggler .bi {
  width: 1.5rem;
  height: 1.5rem;
}

.bd-navbar .navbar-toggler:focus {
  box-shadow: none;
}

.bd-navbar .navbar-brand {
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .bd-navbar .navbar-brand {
    transition: none;
  }
}
.bd-navbar .navbar-brand:hover {
  transform: rotate(-5deg) scale(1.1);
}

.bd-navbar .nav-link {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.bd-navbar .navbar-toggler.active,
.bd-navbar .nav-link.active {
  font-weight: 600;
}

.bd-navbar .navbar-nav-svg {
  display: inline-block;
  vertical-align: -0.125rem;
}

.bd-navbar .offcanvas-lg {
  background-color: var(--bd-violet-bg);
  border-left: 0;
}

@media (max-width: 991.98px) {
  .bd-navbar .offcanvas-lg {
    box-shadow: var(--bs-box-shadow-lg);
  }
}
.bd-navbar .dropdown-toggle:focus:not(:focus-visible) {
  outline: 0;
}

.bd-navbar .dropdown-menu {
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-padding-x: 0.25rem;
  --bs-dropdown-padding-y: 0.25rem;
  --bs-dropdown-link-hover-bg: rgba(var(--bd-violet-rgb), 0.1);
  --bs-dropdown-link-active-bg: rgba(var(--bd-violet-rgb), 1);
  --bs-dropdown-font-size: 0.875rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  box-shadow: var(--bs-box-shadow);
}

.bd-navbar .dropdown-menu li + li {
  margin-top: 0.125rem;
}

.bd-navbar .dropdown-menu .dropdown-item {
  border-radius: 0.25rem;
}

.bd-navbar .dropdown-menu .dropdown-item:active .bi {
  color: inherit !important;
}

.bd-navbar .dropdown-menu .active {
  font-weight: 600;
}

.bd-navbar .dropdown-menu .active .bi {
  display: block !important;
}

.bd-navbar .dropdown-menu-end {
  --bs-dropdown-min-width: 8rem;
}

[data-bs-theme=dark] .bd-navbar {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(255, 255, 255, 0.15);
}

.bd-navbar .bi {
  width: 1em;
  height: 1em;
}

.bd-navbar .nav-link {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.bd-navbar .nav-link.active {
  font-weight: 600;
}

.bd-navbar svg {
  fill: currentColor;
}

.skippy {
  background-color: #4c0bce;
}

.skippy a {
  color: #fff;
}

[data-bs-theme=dark] .subheader {
  background: #444048;
}

nav-link {
  color: var(--bs-white);
}
nav-link:visited {
  color: var(--bs-white);
}

[data-bs-theme=light] .nav-link {
  color: var(--bs-white);
}
[data-bs-theme=light] .nav-link:visited {
  color: var(--bs-white);
}
[data-bs-theme=light] .subheader {
  background: #ffbc89;
}

.login-page {
  /* bg image
  https://commons.wikimedia.org/wiki/File:Paw-print.svg
  https://commons.wikimedia.org/wiki/File:Fox-tail_-_Delapouite_-_game-icons.svg
  This file is licensed under the Creative Commons Attribution-Share Alike 3.0 Unported license.
  */
  background: var(--bs-body-bg) url(/assets/img/pattern.svg);
  background-blend-mode: color-dodge;
}
.login-page .bg-login-image {
  background-size: cover;
  background-position: center center;
  min-height: 500px;
}
.login-page #content {
  padding-bottom: 0;
}
.login-page iframe {
  margin: auto !important;
}
.login-page .login-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  transition: opacity 1s ease-in-out;
}

.navbar .topbar-divider {
  width: 0;
  border-right: 1px solid #ffffff;
  height: 2.375rem;
  margin: auto 0.5rem;
}
.navbar .img-profile {
  height: 2rem;
  width: 2rem;
}

img.fur-profile {
  width: 300px;
  height: 300px;
}

.profile-pic {
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}

.fursuiter-card a {
  transition: all 0.35s ease-in-out;
}
.fursuiter-card a:hover .card {
  border-color: var(--bs-tertiary-bg);
}
.fursuiter-card a:hover .card .card-body {
  background: var(--bs-secondary-bg);
}
.fursuiter-card .card {
  transition: all 0.35s ease-in-out;
}
.fursuiter-card .card .card-body {
  transition: all 0.35s ease-in-out;
  background: var(--bs-tertiary-bg);
}
.fursuiter-card .name {
  font-size: 22px;
  font-weight: bold;
}
.fursuiter-card .idd {
  font-size: 14px;
  font-weight: 600;
}
.fursuiter-card .idd1 {
  font-size: 12px;
}
.fursuiter-card .text span {
  font-size: 13px;
  font-weight: 500;
}

[data-bs-theme=dark] header .dropdown-item.active {
  color: #ffffff;
}
[data-bs-theme=dark] footer .dropdown-item.active {
  color: #ffffff;
}
[data-bs-theme=dark] .btn-canfur-primary-dark, [data-bs-theme=dark] .btn-canfur-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4a148c;
  --bs-btn-border-color: #4a148c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3f1177;
  --bs-btn-hover-border-color: #3b1070;
  --bs-btn-focus-shadow-rgb: 101, 55, 157;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3b1070;
  --bs-btn-active-border-color: #380f69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4a148c;
  --bs-btn-disabled-border-color: #4a148c;
}

[data-bs-theme=light] header .dropdown-item.active {
  color: #333333;
}
[data-bs-theme=light] footer .dropdown-item.active {
  color: #333333;
}
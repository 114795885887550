// [data-bs-theme='light'] {
//   footer {
//     p {
//       color: var(--bs-black);
//     }
//     a {
//       color: var(--bs-black);
//       &:visited {
//         color: var(--bs-black);
//       }
//     }
//   }
// }

// [data-bs-theme='dark'] {
footer {
  p {
    color: var(--bs-white);
  }
  a {
    color: var(--bs-white);

    &:visited {
      color: var(--bs-white);
    }
  }
}
// }

footer {
  a {
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
